import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, ListItemText, ListItem, List, TextareaAutosize, CircularProgress, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { StyledBottomButton, StyledHeadingGrid, StyledGridContainer, StyledNotesButton, StyledPaper } from './styles';

//Components
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";

//custom hook
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from '../../hooks/useUpdate';

//constants
import { API_URLS } from "../../Constants";

//utils
import { getFormattedPrevYrRank } from '../../utils/getFormattedPrevYrRank';
import { getImpactAreaForRoadSegment } from '../../utils/getImpactAreaForRoadSegment';

//context
import { useConnectedProjectsData } from '../../context/ConnectedProjectsDataContext';

//Breadcrumb path items to edit roadway.
const roadwayPathItems = [
    "Dashboard",
    "Roadways",
    "Edit Roadway"
]

//Invoked for editing a roadway.
export default function EditRoadway() {
    const navigate = useNavigate();
    const location = useLocation();
    const { segmentId, navFromView } = location.state;
    const getFetcher = useFetch("get", `${API_URLS["API_GET_ROADWAY_BY_ID"]}?id=${segmentId}`);
    const postFetcher = useUpdate('put', API_URLS["API_UPDATE_ROADWAY"]);

    //React hooks
    const { connectedProjectsData, updateConnectedProjectsData } = useConnectedProjectsData();
    const [roadway, setRoadway] = useState([]);
    const [notes, setNotes] = useState("")
    const [updatedNotes, setUpdatedNotes] = useState("");
    const [edit, setEdit] = useState(false);
    const [previousRanks, setPreviousRanks] = useState([]);


    const [errors, setErrors] = useState([]);

    useEffect(() => {

        if (postFetcher?.serverError && postFetcher?.serverError?.message?.error) {
            //  console.log(postFetcher.serverError.message.error)


            setErrors(postFetcher.serverError.message.error);
        }

    }, [postFetcher.serverError]);

    //Fetch data from API when the component renders.
    useEffect(() => {
        getFetcher.setRequest({});
    }, []);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = getFetcher.serverResponse;
        if (response?.success && response?.data?.length > 0) {
            setPreviousRanks(getFormattedPrevYrRank(response.data[0].previousRanks));
            setRoadway(response.data);
            setNotes(response.data[0].notes);
            setUpdatedNotes(response.data[0].notes);
            if (navFromView) {
                const connectedProjects = response.data[0].connectedProjects.map(proj => {
                    return {
                        ...proj,
                        impactArea: proj.impactArea ? getImpactAreaForRoadSegment(proj.impactArea, segmentId) : "None"
                    }
                })
                updateConnectedProjectsData(connectedProjects);
            }
        }
        else if (response?.error)
            console.error('Error in GET request: ', response.error);
    }, [getFetcher.serverResponse]);

    //Navigate to view roadways is response received successfully
    useEffect(() => {
        const response = postFetcher.serverResponse;
        if (response?.success)
            navigate('/view-roadways');
        else if (!response?.success && response?.error)
            console.error('Error in POST request: ', response.error);
    }, [postFetcher.serverResponse]);

    //Invoked when edit icon is clicked.
    const handleEditNotes = () => {
        setEdit(true);
    };

    //Imvoked when notes text area changes.
    const handleUpdateNotes = (e) => {
        setUpdatedNotes(e.target.value);
    };

    //Invoked when Save is clicked.
    const handleSaveNotes = () => {
        setNotes(updatedNotes);
        setEdit(false);
    };

    //Invoked when Cancel of Notes is clicked.
    const handleCancelEdit = () => {
        setUpdatedNotes(notes);
        setEdit(false);
    };

    //Invoked when "Save" button is clicked.
    const handleSaveBtn = () => {
        const updatedRoadway = {
            segmentId: roadway[0].segmentId,
            notes: updatedNotes,
            connectedProjects: connectedProjectsData
        }
        postFetcher.executeUpdate(updatedRoadway);
    }

    //Invoked when "Cancel" button is clicked.
    const handleCancelBtn = () => {

        //Redirect to View Roadways Page.
        navigate('/view-roadways');
    }

    return (
        <Grid container spacing={2}>

            {/* Breadcrumbs for the page */}
            <Grid aria-label='Breadcrumbs' item xs={12} md={12}>
                <RouterBreadcrumbs pathItems={roadwayPathItems} />
            </Grid>

            {/* Page Title for the page */}
            <Grid aria-label='Page Title' item xs={12} md={12}>
                <Title title="Edit Roadway" />
            </Grid>

            {getFetcher.isLoading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                    <CircularProgress />
                </Grid>
            ) : roadway.length > 0 && (
                <>
                    {/* Roadway Information Heading */}
                    <StyledHeadingGrid aria-label='Roadway Information Heading' item xs={12} sx={{ marginTop: "0px" }}>
                        <Typography variant="h6" gutterBottom>
                            Roadway Information
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Roadway Information */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="TTI Segment ID"
                                                primary={<b>TTI Segment ID</b>}
                                                secondary={roadway[0].segmentId || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Rank"
                                                primary={<b>Rank</b>}
                                                secondary={roadway[0].rank || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Annual Hours of Delay Per Mile"
                                                primary={<b>Annual Hours of Delay Per Mile</b>}
                                                secondary={roadway[0].annualDelayMile || "None"}
                                            />
                                        </ListItem>
                                        {roadway[0].annualCongestionCost && (
                                            <ListItem>
                                                <ListItemText
                                                    aria-label="Annual Congestion Cost in $"
                                                    primary={<b>Annual Congestion Cost in $</b>}
                                                    secondary={roadway[0].annualCongestionCost || "None"}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Year Plan Active"
                                                primary={<b>Year Plan Active</b>}
                                                secondary={roadway[0].yearPlanActive || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Quarter Plan Active"
                                                primary={<b>Quarter Plan Active</b>}
                                                secondary={roadway[0].quarterPlanActive || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </Grid>

                    {/* Roadway Specifics Heading */}
                    <StyledHeadingGrid aria-label="Roadway Specifics Heading" item xs={12}>
                        <Typography variant="h6" >
                            Roadway Specifics
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Roadway Specifics */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Roadway Title"
                                                primary={<b>Roadway Title</b>}
                                                secondary={roadway[0].roadwayTitle || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Roadway Name"
                                                primary={<b>Roadway Name</b>}
                                                secondary={roadway[0].roadwayName || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="From"
                                                primary={<b>From</b>}
                                                secondary={roadway[0].from || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="To"
                                                primary={<b>To</b>}
                                                secondary={roadway[0].to || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="County"
                                                primary={<b>County</b>}
                                                secondary={roadway[0].county || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Region(s)"
                                                primary={<b>Region(s)</b>}
                                                secondary={roadway[0].region && ('name' in roadway[0].region) ? roadway[0].region['name'] : "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </Grid>

                    {/* Previous Ranks Heading */}
                    <StyledHeadingGrid aria-label="Previous Ranks Heading" item xs={12}>
                        <Typography variant="h6">
                            Previous Ranks
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Previous Ranks */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            {previousRanks.length === 0 && (
                                <StyledGridContainer container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">No data available.</Typography>
                                    </Grid>
                                </StyledGridContainer>
                            )}
                            {previousRanks?.length > 0 && (
                                <>
                                    {Array(Math.ceil(previousRanks.length / 6)).fill().map((_, i) => {
                                        const start = i * 6;
                                        const end = start + 6;
                                        const chunk = previousRanks.slice(start, end);
                                        return (
                                            <React.Fragment key={i}>

                                                {/* Years */}
                                                <StyledGridContainer container spacing={2}>
                                                    <Grid item xs={1} sx={{ marginTop: "20px" }}>
                                                        <b>Year</b>
                                                    </Grid>
                                                    {chunk.map((row, index) =>
                                                        <Grid item xs={1} key={index}>
                                                            <List style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <ListItem key={row.year}>
                                                                    <ListItemText
                                                                        aria-label="Year"
                                                                        primary={<i><b>{row.year}</b></i>}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </StyledGridContainer>

                                                {/* Ranks */}
                                                <StyledGridContainer container spacing={2}>
                                                    <Grid item xs={1} sx={{ marginTop: "20px" }}>
                                                        <b>Ranks</b>
                                                    </Grid>
                                                    {chunk.map((row, index) =>
                                                        <Grid item xs={1} key={index}>
                                                            <List style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <ListItem key={row.rank}>
                                                                    <ListItemText
                                                                        aria-label="Rank"
                                                                        primary={row.rank}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </StyledGridContainer>
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </StyledPaper>
                    </Grid>

                    {/* Connected Projects Heading */}
                    < StyledHeadingGrid aria-label="Connected Projects Heading" item xs={12}>
                        <Typography variant="h6">
                            Connected Projects
                            <IconButton aria-label="Edit Connected Projects Icon" onClick={() => { navigate(`/connected-projects`, { state: { selectedRoadway: roadway } }) }}>
                                <EditIcon />
                            </IconButton>
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Connected Projects */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            {connectedProjectsData && connectedProjectsData.length > 0 ? (
                                <>
                                    <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px" }}>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Project Title
                                        </Grid>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Project Type
                                        </Grid>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Impact Area
                                        </Grid>
                                    </StyledGridContainer>
                                    {connectedProjectsData.map((row, index) =>
                                        <StyledGridContainer container spacing={2} key={index} sx={{ paddingBottom: "25px" }}>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.projectTitle || "None"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.projectType || "None"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.impactArea}
                                                </Typography>
                                            </Grid>
                                        </StyledGridContainer>
                                    )}
                                </>
                            ) : (
                                <StyledGridContainer container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">No connected projects available. Click edit icon to add connections.</Typography>
                                    </Grid>
                                </StyledGridContainer>
                            )}
                        </StyledPaper>
                    </Grid>


                    {/* Notes Heading */}
                    <StyledHeadingGrid aria-label="Notes Heading" item xs={12}>
                        <Typography variant="h6">
                            Notes
                            {!edit ? (
                                <IconButton aria-label="Edit Notes Icon" onClick={handleEditNotes}>
                                    <EditIcon />
                                </IconButton>
                            ) :
                                <>
                                    <StyledNotesButton variant="outlined" aria-label="Cancel Notes Button" onClick={handleCancelEdit}>
                                        Cancel
                                    </StyledNotesButton>
                                    <StyledNotesButton variant="outlined" aria-label="Save Notes Button" onClick={handleSaveNotes} sx={{ marginLeft: '8px' }}>
                                        Save
                                    </StyledNotesButton>
                                </>
                            }
                            <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>These notes won't be published</Typography>
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Notes */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid item xs={12}>
                                {!edit ? (
                                    <Typography variant="body1">
                                        {notes}
                                    </Typography>
                                ) : (
                                    <TextareaAutosize
                                        aria-label="Notes Textarea"
                                        value={updatedNotes}
                                        onChange={handleUpdateNotes}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Grid>
                        </StyledPaper>
                    </Grid>


                    <Grid item >
                        {errors?.length > 0 && (
                            <Alert severity="error" style={{ color: "#14375A" }}>
                                <strong>{errors}</strong>
                            </Alert>
                        )}
                    </Grid>

                    {/* Display Buttons */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <>
                            <StyledBottomButton aria-label="Cancel Button" variant="contained" color="primary" onClick={handleCancelBtn}>
                                Cancel
                            </StyledBottomButton>
                            <StyledBottomButton aria-label="Save Button" variant="contained" color="primary" onClick={handleSaveBtn}>
                                Save
                            </StyledBottomButton>
                        </>
                    </Grid>
                </>
            )}
        </Grid>
    );
}